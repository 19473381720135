import React from 'react';
import { Link } from 'gatsby';
import tw from 'twin.macro';
import { useNavigationMenuQuery } from '../../hooks/useNavigationMenuQuery';

const NavMenu = () => {
  const data = useNavigationMenuQuery();

  return (
    <nav css={['background-color: #590000;', tw`text-sm text-gray-300`]}>
      <div css={[tw`flex mt-4 mx-4`]}>
        <ul css={[tw`list-disc ml-4`]}>
          <li css={[tw`my-1`]}>
            <Link to="/">Home</Link>
          </li>
        </ul>
      </div>
      <div css={[tw`flex mt-4 mx-4`]}>
        <div>
          <span css={['font-size: 14px; font-family: "LatoBlack";']}>
            SAMPLE QUERIES
          </span>
          <ul css={[tw`list-disc ml-4`]}>
            {data.allStrapiSampleQueries.nodes.map((query, index) => {
              return (
                <li css={[tw`my-4`]} key={index}>
                  <Link to={query.sampleQueryPath}>{query.Title}</Link>
                </li>
              );
            })}
          </ul>
          <Link
            to="/sample-queries/"
            css={[
              tw`flex items-center ml-4 underline`,
              'font-family: "LatoItalic";',
            ]}
          >
            See all queries
          </Link>
        </div>
      </div>
      <div css={[tw`flex mt-4 mx-4`]}>
        <div>
          <span css={['font-size: 14px; font-family: "LatoBlack";']}>
            TUTORIALS
          </span>
          <ul css={[tw`list-disc ml-4`]}>
            {data.allStrapiTutorials.nodes.map((tutorial, index) => {
              return (
                <li css={[tw`my-4`]} key={index}>
                  <Link to={tutorial.tutorialPath}>{tutorial.Title}</Link>
                </li>
              );
            })}
          </ul>
          <Link
            to="/tutorials"
            css={[
              tw`flex items-center ml-4 underline`,
              'font-family: "LatoItalic";',
            ]}
          >
            See all tutorials
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default NavMenu;
