import React from 'react';
import NavBar from '../Navigation/NavBar';
import Header from '../Navigation/Header';
import tw from 'twin.macro';

const Layout = (props) => {
  return (
    <>
      <NavBar />
      <Header />
      <div css={[tw`w-full`]}></div>
      <div css={[tw`flex`]}>
        <main
          css={[
            tw`ml-0 mt-0 pb-32 md:ml-60 md:mt-16 relative w-full overflow-auto`,
          ]}
        >
          {props.children}
        </main>
      </div>
    </>
  );
};

export default Layout;
