import { useStaticQuery, graphql } from 'gatsby';

export const useNavigationMenuQuery = () => {
  const navigationBarData = useStaticQuery(graphql`
    {
      allStrapiSampleQueries(filter: { ShowInNavigation: { eq: true } }) {
        nodes {
          Title
          sampleQueryPath: gatsbyPath(
            filePath: "/sample-queries/{strapiSampleQueries.Slug}"
          )
        }
      }
      allStrapiTutorials(filter: { ShowInNavigation: { eq: true } }) {
        nodes {
          Title
          tutorialPath: gatsbyPath(
            filePath: "/tutorials/{strapiTutorials.Slug}"
          )
        }
      }
    }
  `);
  return navigationBarData;
};
