import React from 'react';
import tw from 'twin.macro';

const Card = (props) => {
  const styles = [tw`p-4 md:p-8 rounded-sm shadow-md bg-white`].concat(
    props.styles
  );
  return <div css={styles}>{props.children}</div>;
};

export default Card;
