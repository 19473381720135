import React, { useState } from 'react';
import tw from 'twin.macro';

import { logout, getProfile } from '../../utils/auth';
import Menu from '../Icons/Menu';
import Logo from '../../images/ClubAutomationWhiteSmall.png';
import NavMenu from './navMenu';

const Header = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const user = getProfile();

  return (
    <>
      <div
        css={[
          tw`hidden ml-0 md:ml-60 md:flex bg-white fixed top-0 left-0 right-0 z-10 justify-end shadow h-16`,
        ]}
      >
        <div css={[tw`flex items-center px-6 space-x-4`]}>
          <span css={[tw`text-xs`]}>Hello, {user.name}</span>
          <button onClick={logout} css={[tw`text-sm`]}>
            Logout
          </button>
        </div>
      </div>
      <div
        css={[
          `background: linear-gradient(
            90deg,
            rgba(237, 60, 71, 1) 9%,
            rgba(226, 6, 19, 1) 47%,
            rgba(190, 28, 34, 1) 79%,
            rgba(170, 28, 34, 1) 91%
          );`,
          tw`flex ml-0 md:ml-60 md:hidden bg-white justify-between shadow h-16`,
        ]}
      >
        <div css={[tw`flex py-2 pr-7 h-16 items-center`]}>
          <img src={Logo} alt="Logo" css={[tw`px-4 h-8`]} />
        </div>
        <button onClick={() => setMobileOpen((curr) => !curr)}>
          <Menu
            css={[tw`block h-8 w-8 mx-4 self-center md:hidden`]}
            stroke="#fff"
          />
        </button>
      </div>
      <div
        css={[
          'background-color: #590000;',
          tw`absolute h-screen px-8 z-10 left-0 transform -translate-x-full transition duration-200 ease-in-out`,
          mobileOpen && tw`translate-x-0`,
        ]}
      >
        <NavMenu />
      </div>
    </>
  );
};

export default Header;
