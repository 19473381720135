import React from 'react';
import tw from 'twin.macro';
import Logo from '../../images/ClubAutomationWhiteSmall.png';
import NavMenu from './navMenu';

const NavBar = () => {
  return (
    <div
      css={[
        'background-color: #590000;',
        tw`hidden md:block text-sm text-gray-400 fixed top-0 left-0 h-screen w-60`,
      ]}
    >
      <div
        css={[
          `background: linear-gradient(
        90deg,
        rgba(237, 60, 71, 1) 9%,
        rgba(226, 6, 19, 1) 47%,
        rgba(190, 28, 34, 1) 79%,
        rgba(170, 28, 34, 1) 91%
      );`,
          tw`flex bg-red-700 py-2 pr-7 shadow-md h-16 items-center`,
        ]}
      >
        <img src={Logo} alt="Logo" css={[tw`px-4`]} />
      </div>
      <NavMenu />
    </div>
  );
};

export default NavBar;
